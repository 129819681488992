body {
  font-family: var(--custom-font-family, var(--font-family));
}

@font-face {
  font-family: Wix Madefor;
  src: url("variable-font.8768ca74.woff2") format("woff2"), url("variable-font.8b67e11c.woff") format("woff");
}

:root {
  --font-family: "Wix Madefor", sans-serif;
}

h1, h2, h3, h4 {
  margin: 0;
}

h1 {
  font-size: 2.25rem;
  font-weight: 400;
  line-height: 2.5rem;
}

h2 {
  font-size: 1.875rem;
  font-weight: 400;
  line-height: 2.25rem;
}

h3 {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
}

h4 {
  color: var(--color-black);
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.75rem;
}

h5 {
  color: var(--color-black);
  margin-top: .25rem;
  margin-bottom: .25rem;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
}

i {
  font-family: Wix Madefor Oblique;
}

:root {
  --color-primary-50: var(--custom-color-primary-50, #f8fbff);
  --color-primary-100: var(--custom-color-primary-100, #f1f6fc);
  --color-primary-150: var(--custom-color-primary-150, #e6ecf4);
  --color-primary-200: var(--custom-color-primary-200, #c4dcf8);
  --color-primary-250: var(--custom-color-primary-250, #81afe4);
  --color-primary-300: var(--custom-color-primary-300, #006cd1);
  --color-primary-400: var(--custom-color-primary-400, #0056a6);
  --color-primary-500: var(--custom-color-primary-500, #497aa5);
  --color-success-100: var(--custom-color-success-100, #f0fbe6);
  --color-success-200: var(--custom-color-success-200, #b7eb8f);
  --color-success-300: var(--custom-color-success-300, #52c41a);
  --color-success-400: var(--custom-color-success-400, #2a8000);
  --color-success-500: var(--custom-color-success-500, #226800);
  --color-warning-100: var(--custom-color-warning-100, #fdf7d6);
  --color-warning-200: var(--custom-color-warning-200, #ffe58f);
  --color-warning-300: var(--custom-color-warning-300, #faad14);
  --color-warning-400: var(--custom-color-warning-400, #ae5400);
  --color-danger-100: var(--custom-color-danger-100, #fff1f0);
  --color-danger-200: var(--custom-color-danger-200, #f5d0ce);
  --color-danger-300: var(--custom-color-danger-300, #e1000c);
  --color-danger-400: var(--custom-color-danger-400, #c8000a);
  --color-info-100: var(--custom-color-info-100, #f1f6fc);
  --color-info-200: var(--custom-color-info-200, #c4dcf8);
  --color-info-300: var(--custom-color-info-300, #006cd1);
  --color-info-400: var(--custom-color-info-400, #0776dc);
  --color-white: var(--custom-color-white, #fff);
  --color-black: var(--custom-color-black, #1d2334);
  --color-gray-100: var(--custom-color-gray-100, #ededed);
  --color-gray: var(---custom-color-gray, #979797);
}

.button {
  white-space: nowrap;
  text-align: center;
  background-color: #0000;
  border: 1px solid #0000;
  border-radius: .25rem;
  padding: .375rem .75rem;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.429;
  transition: all .3s ease-out;
  display: inline-block;
}

[components-theme="wip"] .button {
  border-radius: 9999px;
}

.button--default {
  border-color: var(--color-primary-200);
  color: var(--color-black);
  background-color: #0000;
}

.button--default:focus:not([disabled]), .button--default:hover:not([disabled]), .button--default:active:not([disabled]), .button--default:active:hover:not([disabled]), .button--default:active:focus:not([disabled]) {
  color: var(--color-primary-300);
  border-color: currentColor;
}

.button--secondary {
  color: var(--color-primary-300);
  background-color: #0000;
  border-color: currentColor;
}

.button--secondary:focus:not([disabled]) {
  background-color: var(--color-primary-100);
  color: var(--color-primary-400);
  border-color: currentColor;
}

.button--secondary:hover:not([disabled]), .button--secondary:active:not([disabled]), .button--secondary:active:hover:not([disabled]), .button--secondary:active:focus:not([disabled]) {
  background-color: var(--color-primary-100);
  color: var(--color-primary-400);
}

.button--primary {
  border-color: var(--color-primary-300);
  background-color: var(--color-primary-300);
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.button--primary:hover:not([disabled]), .button--primary:active:not([disabled]), .button--primary:focus:not([disabled]), .button--primary:active:hover:not([disabled]), .button--primary:active:focus:not([disabled]) {
  border-color: var(--color-primary-400);
  background-color: var(--color-primary-400);
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.button--success {
  border-color: var(--color-success-400);
  background-color: var(--color-success-400);
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.button--success:hover:not([disabled]), .button--success:focus:not([disabled]), .button--success:active:not([disabled]), .button--success:active:focus:not([disabled]), .button--success:active:hover:not([disabled]) {
  border-color: var(--color-success-500);
  background-color: var(--color-success-500);
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.button--warning {
  border-color: var(--color-warning-300);
  background-color: var(--color-warning-300);
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

[components-theme="wip"] .button--warning {
  border-color: var(--color-warning-400);
  color: var(--color-warning-400);
  background-color: #0000;
}

.button--warning:hover:not([disabled]) {
  border-color: var(--color-warning-400);
  background-color: var(--color-warning-400);
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

[components-theme="wip"] .button--warning:hover:not([disabled]) {
  border-color: var(--color-warning-400);
  background-color: var(--color-warning-100);
  color: var(--color-warning-400);
}

.button--warning:focus:not([disabled]), .button--warning:active:not([disabled]) {
  border-color: var(--color-warning-400);
  background-color: var(--color-warning-400);
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

[components-theme="wip"] .button--warning:focus:not([disabled]), [components-theme="wip"] .button--warning:active:not([disabled]) {
  border-color: var(--color-warning-400);
  background-color: var(--color-warning-100);
  color: var(--color-warning-400);
}

.button--warning:active:hover:not([disabled]), .button--warning:active:focus:not([disabled]) {
  border-color: var(--color-warning-400);
  background-color: var(--color-warning-400);
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

[components-theme="wip"] .button--warning:active:hover:not([disabled]), [components-theme="wip"] .button--warning:active:focus:not([disabled]) {
  border-color: var(--color-warning-400);
  background-color: var(--color-warning-100);
  color: var(--color-warning-400);
}

.button--danger {
  border-color: var(--color-danger-300);
  background-color: var(--color-danger-300);
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.button--danger:hover:not([disabled]), .button--danger:active:not([disabled]), .button--danger:focus:not([disabled]), .button--danger:active:hover:not([disabled]), .button--danger:active:focus:not([disabled]) {
  border-color: var(--color-danger-400);
  background-color: var(--color-danger-400);
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.button--loading .spinner {
  width: 1rem;
  height: 1rem;
  top: 1px;
}

.button--xs {
  border-radius: .25rem;
  padding: 1px 5px;
  font-size: .75rem;
  line-height: 1.5;
}

.button--sm {
  border-radius: .25rem;
  padding: .375rem .625rem;
  font-size: .75rem;
  line-height: 1.5;
}

.button--lg {
  border-radius: .375rem;
  padding: .625rem 1rem;
  font-size: 1.125rem;
  line-height: 1.33333;
}

.button--outline {
  border-color: currentColor;
}

.button--with-bg-image {
  border-style: none;
  padding: 0;
}

.button--badge {
  background-color: var(--color-primary-100);
  color: var(--color-primary-500);
  border-style: none;
  border-radius: 9999px;
  padding: .125rem .25rem;
  font-size: .75rem;
  line-height: 1rem;
}

.button--badge:focus, .button--badge:active, .button--badge:hover {
  color: var(--color-primary-300);
}

.button--link {
  color: var(--color-primary-300);
  border-style: none;
  border-radius: 0;
  padding: 0;
}

[components-theme="wip"] .button--link {
  padding: 0;
}

.button--link {
  font-weight: 400;
}

.button--link, .button--link:active, .button--link[disabled] {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  background-color: #0000;
}

.button--link, .button--link:hover, .button--link:focus, .button--link:active {
  border-color: #0000;
}

.button--link:hover, .button--link:focus {
  background-color: #0000;
  text-decoration-line: underline;
}

.button--link[disabled]:hover, .button--link[disabled]:focus {
  color: var(--color-primary-500);
  text-decoration-line: none;
}

.button--link-gray {
  color: var(--color-primary-500);
}

.button[disabled] {
  cursor: not-allowed;
  opacity: .65;
}

.button + .button, .link + .button, .dropdown + .button, .new-dropdown + .button, .tooltip-wrapper + .button, .popover__wrapper + .button, .badge + .button {
  margin-left: .5rem;
}

.button > .button__content:not(:first-child):not(:empty) {
  margin-left: .25rem;
}

.button-group {
  display: flex;
}

.button-group > .button:first-child {
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.button-group > .button:last-child {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.button-group > .button:not(:first-child), .button-group > .new-dropdown:not(:first-child) > .button, .button-group > .dropdown:not(:first-child) > .button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.button-group > .button:not(:last-child), .button-group > .new-dropdown:not(:last-child) > .button, .button-group > .dropdown:not(:last-child) > .button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: -1px;
}

.button-group > .button:hover, .button-group > .button:focus, .button-group > .button:active, .button-group > .button.active {
  z-index: 2;
  position: relative;
}

.button-group > .button + .button, .button-group > .dropdown + .button, .button-group > .button + .dropdown, .button-group > .dropdown + .dropdown {
  margin-left: 0;
}

.link {
  font-size: .875rem;
  line-height: 1.429;
}

.link + .link, .button + .link, .dropdown + .link, .badge + .link, .icon + .link {
  margin-left: .5rem;
}

.link.button {
  padding: .375rem .75rem;
}

.link--xs {
  font-size: .75rem;
  line-height: 1.5;
}

.link--xs.button {
  padding-top: 5px;
  padding-bottom: 5px;
}

.link--sm {
  font-size: .75rem;
  line-height: 1.5;
}

.link--sm.button {
  padding: .375rem .625rem;
}

.link--lg {
  font-size: 18px;
  line-height: 1.33333;
}

.link--lg.button {
  padding: .625rem 1rem;
}

.link > .link__content:not(:first-child):not(:empty) {
  margin-left: .25rem;
}

.table-wrapper {
  max-width: 100%;
  overflow-x: auto;
}

@media (width >= 992px) {
  .table-wrapper {
    overflow-x: visible;
  }
}

.table-wrapper-overflow-visible {
  max-width: 100%;
  overflow-x: visible;
}

@media (width >= 992px) {
  .table-wrapper-overflow-visible {
    overflow-x: visible;
  }
}

.table {
  border-style: solid;
  border-width: 0 0 1px;
  border-color: var(--color-primary-150);
}

.table__cell {
  border-bottom-width: 0;
}

[components-theme="wip"] .table__cell {
  border-style: solid;
  border-top-width: 1px;
  border-left-width: 0;
  border-right-width: 0;
  border-color: var(--color-primary-150);
  font-size: .875rem;
  line-height: 1.25rem;
}

[components-theme="wip"] .table__cell:not(.table__cell--header) {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

[components-theme="wip"] .table__cell--header {
  background-color: var(--color-primary-100);
  text-transform: none;
  color: var(--color-primary-500);
  font-size: .875rem;
  line-height: 1.25rem;
}

[components-theme="wip"] .table__cell--header:first-child {
  border-top-left-radius: .25rem;
}

[components-theme="wip"] .table__cell--header:last-child {
  border-top-right-radius: .25rem;
}

.table__row--indented > .table__cell {
  border-style: none;
}

.highlighted {
  background-color: var(--color-primary-100);
  border-radius: 9999px;
  padding: .25rem .5rem;
}

.toast-container {
  cursor: pointer;
  z-index: 9999;
  width: 300px;
  transition-property: all;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: fixed;
  top: 10px;
  right: 10px;
}

.toast {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  opacity: .75;
  border-radius: .25rem;
  padding: 1rem;
}

.toast + .toast {
  margin-top: .5rem;
}

.toast:hover {
  opacity: 1;
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.toast--success {
  background-color: var(--color-success-300);
}

.toast--danger {
  background-color: var(--color-danger-300);
}

.toast--warning {
  background-color: var(--color-warning-300);
}

.toast--info {
  background-color: var(--color-info-300);
}

.redesign-status {
  z-index: 2000;
  position: fixed;
  bottom: 30px;
  right: 30px;
}

.input-group > .elm-datepicker--container > .form-control.elm-datepicker--input {
  float: inherit;
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.debug-info {
  color: #a9a9a9;
  text-align: center;
  z-index: 102;
  background-color: #000;
  font-size: 10px;
}

.tags-list {
  border-width: 1px;
  border-color: var(--color-primary-200);
  border-radius: 4px;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  display: inline-flex;
  box-shadow: inset 0 1px 1px #00000014;
}

.tags-list:focus-within {
  border: 1px solid #66afe9;
  box-shadow: inset 0 1px 1px #00000013, 0 0 8px #66afe999;
}

.tags-list.invalid {
  border-width: 1px;
  border-color: var(--color-primary-200);
  box-shadow: inset 0 1px 1px #00000013, 0 0 8px #f00c;
}

.tags-list .tag-item {
  color: #fff;
  background-color: #428bca;
  border-radius: 3px;
  align-items: center;
  height: 26px;
  margin: 2px 3px;
  padding: 5px 10px;
  line-height: 25px;
  display: flex;
}

.tags-list .input-wrapper input {
  width: 90px;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  border: none;
  border-radius: 4px;
  outline: 0;
}

.tags-list .input-wrapper input .form-control:focus {
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  outline: 0;
}

.tags-list .input-wrapper input.feedback-errored {
  color: red;
}

.coverage-rule__unpublished {
  display: flex;
}

.coverage-rule__unpublished__arrow {
  width: 1.75rem;
}

.coverage-rule__unpublished__warning {
  width: 100%;
}

.coverage-rule__sentence__amount {
  width: 100px;
}

:root {
  --topnav-height: 55px;
  --mobile-nav-height: 0px;
  --total-header-height: 0px;
}

.navbar-wrapper {
  z-index: 100;
  height: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.sidenav, .topnav {
  overflow: visible;
}

.sidenav__item, .topnav__item {
  cursor: pointer;
  border-radius: 3px;
  transition: all .3s ease-out;
}

.sidenav__item > a, .sidenav__item > .button, .topnav__item > a, .topnav__item > .button {
  color: var(--color-primary-500);
  border-left: 2px solid #0000;
  border-radius: 3px;
  justify-content: flex-start;
  align-items: center;
  padding: .75rem .5rem;
  font-weight: 400;
  transition: all .3s ease-out;
  display: flex;
}

.sidenav__item > a:hover, .sidenav__item > a:active, .sidenav__item > a:focus, .sidenav__item > .button:hover, .sidenav__item > .button:active, .sidenav__item > .button:focus, .topnav__item > a:hover, .topnav__item > a:active, .topnav__item > a:focus, .topnav__item > .button:hover, .topnav__item > .button:active, .topnav__item > .button:focus {
  color: #527fbb;
  text-decoration: none;
}

.sidenav__item .sidenav__icon--show-more, .topnav__item .sidenav__icon--show-more {
  fill: none;
  height: 12px;
  margin-left: auto;
  margin-right: 0;
  transform: rotate(90deg);
}

.sidenav__disabledLink, .topnav__disabledLink {
  color: var(--color-primary-500);
  cursor: default;
  margin-right: .75rem;
  display: flex;
}

.sidenav__icon, .topnav__icon {
  fill: currentColor;
  height: 1.25rem;
  padding-right: .75rem;
}

.sidenav__icon--launch, .topnav__icon--launch {
  height: 1rem;
  padding-left: .75rem;
}

.sidenav__icon--logout, .topnav__icon--logout {
  height: 1rem;
}

.sidenav__subnav .topnav__icon--show-more, .sidenav__subnav .sidenav__icon--show-more, .topnav__subnav .topnav__icon--show-more, .topnav__subnav .sidenav__icon--show-more {
  transition: all .3s ease-out;
}

.sidenav__subnav--open .topnav__icon--show-more, .sidenav__subnav--open .sidenav__icon--show-more, .topnav__subnav--open .topnav__icon--show-more, .topnav__subnav--open .sidenav__icon--show-more {
  transition: all .3s ease-out;
  transform: rotate(-90deg);
}

.sidenav__subnav--open .sidenav__subnav__content, .topnav__subnav--open .sidenav__subnav__content {
  flex-direction: column;
  display: flex;
}

.topnav {
  display: none;
}

@media (width >= 768px) {
  .topnav {
    height: var(--topnav-height);
    border-bottom: 1px solid var(--color-primary-150);
    z-index: 101;
    background-color: #fff;
    justify-content: space-between;
    width: 100%;
    padding: .75rem 1.25rem;
    display: flex;
    left: 0;
  }
}

.topnav__logo {
  width: 90px;
  margin-right: .75rem;
}

.topnav__left {
  align-items: center;
  display: flex;
}

.topnav__left .dropdown button {
  margin-left: 0;
  margin-right: 1rem;
  padding: 0;
}

.topnav__left .dropdown-menu {
  min-width: 110px;
}

.topnav__left .dropdown-menu li > a {
  color: #005377;
  padding: 3px 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.topnav__left .dropdown-menu .dropdown-title {
  color: var(--color-primary-500);
  text-transform: uppercase;
  letter-spacing: 1px;
  pointer-events: none;
  padding: 3px 10px 6px 16px;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
}

.topnav__left--content {
  align-items: center;
  margin-left: 1.25rem;
  font-size: 14px;
  display: flex;
}

.topnav__left--content a {
  color: var(--color-primary-500);
}

.topnav__left--content a:hover, .topnav__left--content a:active, .topnav__left--content a:focus {
  color: #527fbb;
  text-decoration: none;
}

.topnav__right {
  align-items: center;
  display: flex;
}

.topnav__right .topnav__item {
  margin-right: .75rem;
}

.topnav__right .topnav__item a:hover {
  color: #527fbb;
}

.topnav__search {
  margin-right: 1.25rem;
}

.topnav__search > .button {
  color: var(--color-primary-500);
  width: 15vw;
}

.topnav__search > .button:hover {
  color: var(--color-primary-500);
  background-color: #fff;
}

.topnav__search > .button > .button__content > .topnav__search-content > div:first-child > svg {
  margin-bottom: -2px;
}

.topnav__search-content {
  justify-content: space-between;
  display: flex;
}

.topnav__subnav .sidenav__subnav__content {
  width: 190px;
  padding: .75rem;
}

.topnav__subnav .sidenav__subnav__content .sidenav__item {
  padding: 0;
}

.topnav__subnav--open .sidenav__subnav__content {
  background: #fff;
  border-radius: 3px;
  margin-top: -2px;
  margin-left: -.5rem;
  position: absolute;
  box-shadow: 1px 0 2px #0000000d, 0 2px 9px #0000001a;
}

.topnav__icon--location {
  height: 1rem;
  margin-bottom: -3px;
  padding-right: .5rem;
}

.topnav__icon--change {
  height: 1rem;
  margin-bottom: -3px;
  margin-left: .75rem;
  padding-right: 0;
}

.topnav__icon--show-more {
  fill: none;
  height: 12px;
  margin-bottom: -.125rem;
  margin-left: .75rem;
  transform: rotate(90deg);
}

.sidenav {
  white-space: nowrap;
  z-index: 100;
  background-color: #fff;
  flex-direction: column;
  justify-content: flex-start;
  width: 80%;
  height: 100vh;
  transition: all .3s ease-out;
  display: flex;
  position: relative;
  left: -80%;
}

@media (width >= 768px) {
  .sidenav {
    border-right: 1px solid var(--color-primary-150);
    width: 60px;
    height: calc(100vh - var(--topnav-height));
    padding-top: .75rem;
    left: 0;
  }
}

.sidenav--slide-in {
  left: 0;
}

.sidenav--slide-in:after {
  opacity: .5;
  content: "";
  background-color: #000;
  width: 768px;
  transition: all .3s ease-out;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 100%;
}

@media (width >= 768px) {
  .sidenav--slide-in:after {
    content: none;
  }
}

.sidenav__header {
  color: var(--color-primary-500);
  margin: .75rem 0;
  padding: .75rem 1rem;
  font-size: 12px;
  display: flex;
}

@media (width >= 768px) {
  .sidenav__header {
    margin: 1.25rem 0;
  }
}

.sidenav__header--right {
  text-align: right;
  flex-direction: column;
  flex: 1;
  align-items: flex-end;
  display: flex;
}

.sidenav__header svg {
  width: 14px;
  margin-bottom: -4px;
  margin-right: 6px;
}

@media (width >= 768px) {
  .sidenav__header {
    display: none;
  }
}

.sidenav__logo {
  width: 90px;
  margin-right: .75rem;
}

.sidenav__search {
  margin-bottom: 1rem;
  padding: 0 1rem;
}

.sidenav__search button {
  color: var(--color-primary-500);
  width: 100%;
}

.sidenav__search button:hover {
  color: var(--color-primary-500);
  background-color: #fff;
}

.sidenav__search-content {
  justify-content: space-between;
  display: flex;
}

@media (width >= 768px) {
  .sidenav__search {
    display: none;
  }
}

.sidenav__main {
  padding: 0 1rem;
  font-size: 14px;
  overflow: scroll;
}

@media (width >= 768px) {
  .sidenav__main {
    font-size: inherit;
    scrollbar-width: none;
    padding: 0 10px;
  }
}

.sidenav__main::-webkit-scrollbar {
  display: none;
}

@media (width >= 768px) {
  .sidenav .sidenav__main--mobile {
    display: none;
  }
}

.sidenav .sidenav__main--desktop {
  display: none;
}

@media (width >= 768px) {
  .sidenav .sidenav__main--desktop {
    flex-direction: column;
    display: flex;
    overflow: visible;
  }
}

.sidenav .sidenav__main--desktop .sidenav__item {
  margin: .3rem 0;
}

.sidenav .sidenav__main--desktop .sidenav__item a, .sidenav .sidenav__main--desktop .sidenav__item .button {
  color: var(--color-primary-500);
  border-width: 0 0 0 2px;
  border-left-style: solid;
  border-left-color: #0000;
  border-radius: 3px;
  justify-content: flex-start;
  align-items: center;
  padding: .6em .6em .6em .5em;
  font-weight: 400;
  transition: all .3s ease-out;
  display: flex;
}

.sidenav .sidenav__main--desktop .sidenav__item a:hover, .sidenav .sidenav__main--desktop .sidenav__item a:active, .sidenav .sidenav__main--desktop .sidenav__item a:focus, .sidenav .sidenav__main--desktop .sidenav__item .button:hover, .sidenav .sidenav__main--desktop .sidenav__item .button:active, .sidenav .sidenav__main--desktop .sidenav__item .button:focus {
  color: #527fbb;
  text-decoration: none;
}

.sidenav .sidenav__main--desktop .sidenav__item .sidenav__icon {
  padding-right: 0;
}

.sidenav .sidenav__main--desktop .sidenav__item--company a {
  padding: .6em .3em .6em .25em !important;
}

.sidenav .sidenav__main--desktop .sidenav__active > a, .sidenav .sidenav__main--desktop .sidenav__active .tooltip-wrapper a, .sidenav .sidenav__main--desktop .sidenav__active .tooltip-wrapper .button {
  color: #527fbb;
  background-color: #eaf6fe;
  border-left: 2px solid #527fbb;
  text-decoration: none;
}

.sidenav .flyout-menu__content .sidenav__subnav__content {
  flex-direction: column;
  display: flex;
}

.sidenav .flyout-menu__content .sidenav__subnav__content .sidenav__item {
  white-space: normal;
  margin: 0;
  padding: 0;
}

.sidenav .flyout-menu__content .sidenav__subnav__content .sidenav__item > a, .sidenav .flyout-menu__content .sidenav__subnav__content .sidenav__item > .button {
  padding: .8em 1em;
}

.sidenav .flyout-menu-overlay + div a {
  color: #527fbb !important;
}

.sidenav__subnav__content {
  display: none;
}

.sidenav__subnav__content .sidenav__item {
  padding-left: .75rem;
}

.sidenav__subnav--open .sidenav__subnav__content {
  border-left: 2px solid --var(color-primary-150);
  margin: .25rem 0 0 1.25rem;
}

.sidenav__subnav--open .sidenav__subnav__content .sidenav__item > a {
  padding: .75rem .5rem;
}

.sidenav__divider {
  border-bottom: 2px solid --var(color-primary-150);
  width: 60%;
  margin: .75rem .25rem;
}

.sidenav__icon--logout {
  margin-left: 3px;
}

.sidenav__active {
  background-color: #fff;
  font-weight: bold;
}

.sidenav__active > a {
  color: #527fbb;
  background-color: #eaf6fe;
  border-left: 2px solid #527fbb;
  text-decoration: none;
}

.sidenav__bottom {
  margin-top: auto;
  margin-bottom: .75rem;
}

.sidenav__bottom .sidenav__item {
  padding: 0 1rem;
}

@media (width >= 768px) {
  .sidenav__bottom .sidenav__item {
    display: none;
  }
}

.sidenav svg:not(:root) {
  overflow: visible;
}

.elm-mobile-nav {
  height: var(--topnav-height);
  z-index: 90;
  border-bottom: 1px solid var(--color-primary-150);
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: .75rem 1rem;
  display: flex;
  position: fixed;
}

.elm-mobile-nav > a > img {
  width: 90px;
  margin-right: .75rem;
}

@media (width >= 768px) {
  .elm-mobile-nav {
    display: none;
  }
}

.elm-mobile-nav .dropdown-menu {
  min-width: 110px;
}

.elm-mobile-nav .dropdown-menu li > a {
  color: #005377;
  padding: 3px 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.elm-mobile-nav .dropdown-menu .dropdown-title {
  color: var(--color-primary-500);
  text-transform: uppercase;
  letter-spacing: 1px;
  pointer-events: none;
  padding: 3px 16px 6px;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
}

.sidenav-close {
  margin-top: 21px;
  position: absolute;
  right: 15px;
}

.sidenav-open, .sidenav-close {
  z-index: 120;
}

.sidenav-open:active, .sidenav-open:focus, .sidenav-close:active, .sidenav-close:focus {
  outline: none !important;
}

@media (width >= 768px) {
  .sidenav-open, .sidenav-close {
    display: none;
  }
}

.sidenav__item.sidenav__subnav > .button {
  width: 100%;
}

.topnav__item .button__icon, .topnav__item .link__icon, .sidenav__item .link__icon, .sidenav__item .button__icon {
  height: 1rem;
}

.sidenav__item .button__icon, .sidenav__item .link__icon {
  margin-right: .75rem;
}

.sidenav__icon {
  margin-bottom: -4px;
}

.topnav__search > .button {
  border-radius: .25rem;
}

.topnav__item > a, .topnav__item > .button {
  display: inline-block;
}

.global-search__results {
  margin-top: .75rem;
}

.global-search__results .list-group-item {
  border: 0;
  border-radius: 4px;
  padding: 1rem;
}

.global-search__results .list-group-item > a:hover {
  color: var(--color-primary-300);
}

.global-search__results .list-group-item.active, .global-search__results .list-group-item.active:hover {
  color: var(--color-primary-300);
  background-color: #eaf6fe;
  border: 0;
}

.global-search__results .list-group-item.active .global-search__action, .global-search__results .list-group-item.active:hover .global-search__action {
  visibility: visible;
}

.global-search__icon {
  fill: currentColor;
  height: 1.25rem;
  margin-right: .25rem;
  position: relative;
  top: 4px;
  left: -3px;
}

.global-search__action {
  float: right;
  stroke: #447ab2;
  visibility: hidden;
  height: 1.25rem;
  padding-top: 7px;
}

.global-search__footer {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.global-search__pagination ul + div {
  margin: 1rem 0 0 .75rem;
}

.global-search__legend {
  color: var(--color-primary-500);
  align-items: center;
  display: flex;
}

.global-search__legend svg {
  margin: 0 .25rem 0 .75rem;
}

.form-control.search-input {
  box-shadow: none;
  border: none;
  margin-bottom: .5em;
  padding-left: 2em;
  font-size: 18px;
}

.form-control.search-input:focus {
  box-shadow: none;
  border-color: none;
}

.payments-list .payment__item, .payments-list .payment__refund, .payments-history .payment__item, .payments-history .payment__refund {
  padding: .75rem 0;
}

.payments-list .payment__item, .payments-history .payment__item {
  border-top-width: 1px;
  border-color: var(--color-primary-100);
}

.payments-list .payment__refund, .payments-history .payment__refund {
  border-top-width: 1px;
  border-color: var(--color-primary-100);
  margin-top: .75rem;
  padding-bottom: 0;
}

.payments-list .payment__item:last-child, .payments-history .payment__item:last-child {
  border-bottom: none;
}

.payments-list .payment__scheduled, .payments-history .payment__scheduled {
  border-top-width: 1px;
  border-color: var(--color-primary-100);
  padding: .75rem 0;
}

.invoice-revisions .revision__item {
  border-bottom-width: 1px;
  border-color: var(--color-primary-100);
  padding: .75rem 0;
}

.invoice-revisions .revision__item:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.invoice-revisions .revision__item--voided {
  border-top-width: 1px;
  border-color: var(--color-primary-100);
  border-bottom: none;
  margin-top: .75rem;
  padding: .75rem 0 0;
}

.list {
  border-style: solid;
  border-width: 1px 0 0;
  border-color: var(--color-primary-150);
}

.list__item {
  padding: .75rem 1rem;
  display: block;
}

.list__item:not(:last-child) {
  border-style: solid;
  border-width: 0 0 1px;
  border-color: var(--color-primary-150);
}

.list__item--clickable {
  cursor: pointer;
  transition: all .3s ease-out;
}

.list__item--clickable:hover, .list__item--clickable:focus {
  background-color: var(--color-primary-50);
}

.style-guide-example {
  border-width: 1px;
  border-color: var(--color-primary-100);
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  border-radius: .375rem;
  padding: 1rem;
}

.style-guide-example + .style-guide-example {
  margin-top: .5rem;
}

.style-guide-example__separator {
  border-style: solid;
  border-top-width: 0;
  border-bottom-width: 1px;
  border-color: var(--color-primary-100);
  margin-top: .25rem;
  margin-bottom: 1rem;
}

.style-guide-example__code {
  background-color: #eee;
  margin: 1rem -1rem -1rem;
}

.style-guide-example__code > pre {
  border-style: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin: 0;
  padding: 1rem;
}

.stepper__vertical {
  flex-direction: column;
  justify-content: center;
  padding-top: 10px;
  display: flex;
  overflow: hidden;
}

.stepper__vertical .stepper__step {
  flex-flow: wrap;
  align-items: flex-start;
  margin-bottom: -.5rem;
  margin-left: -.25rem;
  margin-right: -.25rem;
  display: flex;
}

.stepper__vertical .stepper__step + .flex-row {
  margin-top: .5rem;
}

.stepper__vertical .stepper__step {
  flex-wrap: nowrap;
  margin: 0;
}

.stepper__vertical .stepper__text {
  height: 100%;
  color: var(--color-primary-500);
  align-items: center;
  margin-top: .3rem;
  padding-left: 10px;
  display: flex;
}

.stepper__vertical .stepper__subtitle {
  height: 100%;
  color: var(--color-primary-500);
  align-items: center;
  margin-top: .3rem;
  margin-bottom: .3rem;
  padding-left: .6rem;
  font-size: .875rem;
  line-height: 1.25rem;
  display: flex;
}

.stepper__vertical .stepper__description {
  width: 75%;
}

.stepper__vertical .stepper__line {
  width: 2rem;
  margin: .3rem 0;
  transform: rotate(90deg);
}

.stepper {
  -ms-overflow-style: none;
  scrollbar-width: none;
  justify-content: center;
  margin-top: .25rem;
  margin-bottom: .75rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

.stepper::-webkit-scrollbar {
  display: none;
}

.stepper__step {
  align-items: center;
  display: flex;
}

.stepper__step:last-child {
  margin-right: 0;
}

.stepper__circle {
  border-style: solid;
  border-color: var(--color-primary-400);
  vertical-align: middle;
  border-width: 1px;
  border-radius: 9999px;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  font-size: .75rem;
  line-height: 1.7rem;
  display: flex;
}

.stepper__circle .icon {
  border-color: var(--color-primary-400);
  width: .75rem;
  height: .75rem;
  margin-top: .3rem;
}

.stepper__circle svg {
  fill: var(--color-primary-400);
}

.stepper__circle .glyphicon {
  width: 1rem;
  height: 1rem;
  line-height: 1.7rem;
}

.stepper__step--previous {
  cursor: pointer;
}

.stepper__step--current .stepper__circle {
  background-color: var(--color-primary-400);
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.stepper__step--previous .stepper__circle {
  color: var(--color-primary-300);
}

.stepper__step--next .stepper__circle {
  border-style: solid;
  border-color: var(--color-primary-300);
  color: var(--color-primary-500);
  border-width: 1px;
  font-weight: 400;
}

.stepper__step--disabled .stepper__circle {
  border-style: solid;
  border-width: 1px;
  border-color: var(--color-primary-200);
  color: var(--color-primary-500);
}

.stepper__text {
  height: 100%;
  color: var(--color-primary-500);
  align-items: center;
  padding-left: 6px;
  font-size: .875rem;
  line-height: 1.25rem;
  display: none;
}

.stepper__step--current, .stepper__step--label {
  font-weight: 400;
}

.stepper__step--current .stepper__text, .stepper__step--previous, .stepper__text {
  color: var(--color-black);
}

.stepper__step--previous .stepper__text a {
  text-decoration-line: underline;
}

.stepper__step--next, .stepper__step--disabled, .stepper__step--current {
  pointer-events: none;
}

.stepper__line {
  width: 1rem;
  margin: .25rem;
}

.stepper__line hr, .stepper__step--current .stepper__line hr, .stepper__step--next .stepper__line hr {
  border-color: var(--color-primary-300);
}

.stepper__step--disabled .stepper__line hr {
  border-color: var(--color-primary-200);
}

@media (width >= 992px) {
  .stepper {
    padding-left: 0;
    overflow-x: scroll;
  }

  .stepper__step {
    margin-right: 1rem;
  }

  .stepper__circle {
    width: 2rem;
    height: 2rem;
    font-size: 1rem;
    line-height: 1.9rem;
  }

  .stepper__circle .icon {
    width: 1rem;
    height: 1rem;
    margin-top: .45rem;
  }

  .stepper__text {
    padding-left: .5rem;
    font-size: 1rem;
    line-height: 1.5rem;
    display: none;
  }

  .stepper__step--current .stepper__text, .stepper__step--current .stepper__subtitle {
    display: inline-flex;
  }

  .stepper__line {
    width: 2rem;
    margin-left: 1rem;
    margin-right: 0;
  }
}

.panel {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  border-radius: .25rem;
  margin-bottom: 0;
}

.panel-default > .panel-heading {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  border-bottom-width: 0;
}

.panel-heading h4 {
  color: var(--color-black);
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.panel + .panel {
  margin-top: 1rem;
}

[components-theme="wip"] .panel-footer {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  border-top-width: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

[components-theme="wip"] .panel > .table-wrapper {
  width: calc(100% - 2rem);
  margin-left: 1rem;
  margin-right: 1rem;
}

.practice-pulse-panel > .panel-heading {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  border-bottom-width: 0;
}

.practice-pulse-panel > .panel-body {
  padding-top: 0;
}

.border-left-panel {
  border-top: none;
  border-bottom: none;
  border-right: none;
  border-left: 2px solid var(--color-primary-150);
  padding-bottom: 8rem;
  padding-left: 2.5rem;
  padding-right: 1.5rem;
}

.badge {
  white-space: nowrap;
  background-color: var(--color-gray-100);
  text-transform: capitalize;
  --tw-text-opacity: 1;
  color: var(--color-black);
  border-radius: 9999px;
  padding: .25rem .5rem .25rem 1rem;
  font-size: .75rem;
  font-weight: 400;
  line-height: 1;
  display: inline-block;
  position: relative;
}

.badge:before {
  content: "";
  background-color: currentColor;
  border-radius: 9999px;
  width: 5px;
  height: 5px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 6px;
  transform: translateY(-50%);
}

.badge--lg {
  font-size: .75rem;
  line-height: 1rem;
}

.badge--inline {
  display: inline;
}

.dropdown .badge--inline, .dropup .badge--inline {
  display: inline-block;
}

.badge--success {
  background-color: var(--color-success-100);
  color: var(--color-success-400);
}

.badge--warning {
  background-color: var(--color-warning-100);
  color: var(--color-warning-300);
}

.badge--danger {
  background-color: var(--color-danger-100);
  color: var(--color-danger-300);
}

.badge--primary, .badge--info {
  background-color: var(--color-primary-100);
  color: var(--color-primary-300);
}

.badge.badge--icon {
  padding-left: .5rem;
}

.badge.badge--icon:before {
  display: none;
}

.badge.badge svg {
  fill: currentColor;
  vertical-align: middle;
  height: .625rem;
  margin-top: -3px;
  margin-right: 3px;
  display: inline-block;
}

.badge + .badge, .link + .badge {
  margin-left: .5rem;
}

.nav-tabs {
  flex-wrap: wrap;
  margin-bottom: -1px;
  display: flex;
  position: relative;
}

.nav-tabs__item {
  white-space: nowrap;
  border: 1px solid #0000;
  align-items: center;
  margin-bottom: -1px;
  display: flex;
  position: relative;
}

[components-theme="wip"] .nav-tabs__item {
  border-style: none;
}

[components-theme="wip"] .nav-tabs__item + .nav-tabs__item {
  margin-left: 1.5rem;
}

.nav-tabs__item > a, .nav-tabs__item > .button--link {
  padding: .75rem 1rem;
}

[components-theme="wip"] .nav-tabs__item > a, [components-theme="wip"] .nav-tabs__item > .button--link {
  color: var(--color-primary-500);
  padding: 1rem 0;
}

[components-theme="wip"] .nav-tabs__item:first-of-type > a, [components-theme="wip"] .nav-tabs__item:first-of-type > .button--link {
  padding-left: 0;
}

.nav-tabs__item > .dropdown {
  padding: .75rem;
}

[components-theme="wip"] .nav-tabs__item > .dropdown {
  padding-left: 0;
  padding-right: 0;
}

.nav-tabs__item--active {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  border: 1px solid var(--color-primary-150);
  border-bottom: 1px solid #0000;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

[components-theme="wip"] .nav-tabs__item--active {
  background-color: #0000;
  border-style: none;
}

.nav-tabs__item--active > a {
  cursor: default;
  color: var(--color-black);
}

[components-theme="wip"] .nav-tabs__item--active > a {
  color: var(--color-primary-300);
}

.nav-tabs__item--active > a {
  text-decoration: none;
}

.nav-tabs__underline {
  transform-origin: 0;
  background-color: var(--color-primary-300);
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
}

[components-theme="wip"] .nav-tabs__underline {
  display: block;
}

.nav-tabs__underline {
  z-index: 5;
  width: 1px;
  height: 2px;
  transition: all .3s ease-out;
}

[components-theme="wip"] .layout-header {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  border-style: solid;
  border-width: 0 0 1px;
  border-color: var(--color-primary-150);
  margin: -30px -2.25rem 1.5rem;
  padding-top: 1rem;
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.profile-picture {
  border-radius: 9999px;
  place-content: center;
  display: flex;
  overflow: hidden;
}

.profile-picture img {
  object-fit: cover;
}

@media (width >= 768px) {
  .profile-picture {
    margin-top: .5rem;
  }
}

.skeleton {
  background-color: var(--color-primary-150);
  color: #0000;
  background-repeat: no-repeat;
  border-radius: .25rem;
  width: 100%;
  margin-bottom: -3px;
  animation: 1.5s ease-out infinite shimmer;
  display: inline-block;
  -webkit-mask: linear-gradient(135deg, #000 40%, #0005 50%, #000 60%) 100% / 300% 100%;
  mask: linear-gradient(135deg, #000 40%, #0005 50%, #000 60%) 100% / 300% 100%;
}

.skeleton--block {
  display: block;
}

.skeleton + .skeleton--block {
  margin-top: .25rem;
}

.skeleton--text {
  height: 1.1em;
}

.skeleton--input {
  height: 34px;
  padding-left: .75rem;
  padding-right: .75rem;
}

.skeleton--input-sm {
  height: 30px;
  padding-left: .5rem;
  padding-right: .5rem;
}

.skeleton--input-xs {
  height: 26px;
  padding-left: .25rem;
  padding-right: .25rem;
}

.skeleton--card {
  height: 5rem;
}

@keyframes shimmer {
  100% {
    -webkit-mask-position: 0;
  }
}

.view-details {
  padding: 0 1rem;
}

.view-details .button.close {
  color: #000;
  font-size: 1.5rem;
  transform: translateY(-25%);
}

.view-details .category-resource-filter p.form-control-static {
  padding-top: 0;
}

table td .checkbox {
  margin-top: 0;
  margin-bottom: 0;
}

select.select--between-text {
  width: auto;
  height: auto;
  padding: 0;
  display: inline;
}

.form-control-select > .button {
  border-radius: .25rem;
  height: 34px;
  padding-left: .75rem;
  padding-right: .75rem;
}

.form-control-select > .dropdown-menu, .ui-select-choices {
  width: 100%;
  max-height: 60vh;
}

.form-control-select__caret {
  height: 10px;
  margin-left: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-60%);
}

.dropdown > .button--xs .form-control-select__caret {
  height: auto;
  padding-right: .75rem;
  transform: translateY(-50%);
}

.form-group {
  flex-direction: column;
  margin-left: 0;
  margin-right: 0;
  display: flex;
}

@media (width >= 768px) {
  .form-group {
    flex-direction: row;
  }

  .form-group > .control-label + *, .form-group > label + * {
    margin-left: 1rem;
  }
}

.form-group + .form-group {
  margin-top: 1rem;
}

.form-group__required {
  color: var(--color-danger-300);
  margin-left: .25rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  display: inline;
  top: .75rem;
  right: -.75rem;
}

.dropdown .form-group__required, .dropup .form-group__required {
  display: inline-block;
}

@media (width >= 768px) {
  .form-group__required {
    margin-left: 0;
    position: absolute;
  }
}

.form-group__required {
  line-height: 0;
}

.form-group__content {
  width: 100%;
}

@media (width >= 768px) {
  [components-theme="wip"] .form-group--label-above {
    flex-direction: column;
  }
}

[components-theme="wip"] .form-group--label-above > .control-label {
  text-align: left;
  width: fit-content;
}

@media (width >= 768px) {
  [components-theme="wip"] .form-group--label-above > .control-label:not(:empty) + *, [components-theme="wip"] .form-group--label-above > label:not(:empty) + * {
    margin-left: 0;
  }
}

.control-label {
  padding-top: .5rem;
  position: relative;
}

@media (width >= 768px) {
  .control-label {
    text-align: right;
  }
}

.control-label {
  flex-grow: 0;
  flex-shrink: 0;
}

.elm-datepicker--container {
  position: relative;
}

.elm-datepicker--picker {
  border-width: 1px;
  border-color: var(--color-primary-200);
  z-index: 10;
  background-color: #fff;
  flex-direction: column;
  min-width: 275px;
  display: flex;
  position: absolute;
}

.elm-datepicker--picker-header {
  align-items: center;
  display: flex;
}

.elm-datepicker--prev-container, .elm-datepicker--next-container {
  cursor: pointer;
  flex: 0 auto;
}

.elm-datepicker--month-container {
  flex-direction: column;
  flex: auto;
  padding: .5em;
  display: flex;
}

.elm-datepicker--month, .elm-datepicker--year {
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  background: #fff;
  border: none;
  flex: auto;
  font-size: 1em;
  font-weight: bold;
}

.elm-datepicker--month:hover, .elm-datepicker--year:hover {
  opacity: .7;
}

.elm-datepicker--year {
  font-size: .9em;
}

.elm-datepicker--prev, .elm-datepicker--next {
  background-color: inherit;
  border: 6px solid #0000;
  width: 0;
  height: 0;
  padding: 0 .2em;
  display: block;
}

.elm-datepicker--prev {
  border-right-color: #000;
}

.elm-datepicker--prev:hover {
  border-right-color: #0009;
}

.elm-datepicker--next {
  border-left-color: #000;
}

.elm-datepicker--next:hover {
  border-left-color: #0009;
}

.elm-datepicker--table {
  border-spacing: 0;
  border-collapse: collapse;
  font-size: .8em;
}

.elm-datepicker--table td {
  text-align: center;
  width: 2em;
  height: 2em;
}

.elm-datepicker--row {
  border-top: 1px solid #f2f2f2;
}

.elm-datepicker--dow {
  border-bottom-width: 1px;
  border-color: var(--color-primary-200);
  text-transform: uppercase;
  cursor: default;
  color: #8b8989;
  background: #fff;
}

.elm-datepicker--day {
  cursor: pointer;
  padding: .6em .8em;
}

.elm-datepicker--day:hover {
  background-color: var(--color-primary-300);
  color: #fff;
  border-radius: 5px;
}

.elm-datepicker--disabled {
  cursor: default;
  color: var(--color-primary-150);
}

.elm-datepicker--disabled:hover {
  background: inherit;
}

.elm-datepicker--picked {
  color: #fff;
  background: #337ab7;
  border-radius: 7px;
}

.elm-datepicker--picked:hover {
  background-color: var(--color-primary-300);
}

.elm-datepicker--today {
  font-weight: bold;
}

.elm-datepicker--other-month {
  color: var(--color-primary-500);
}

.elm-datepicker--other-month.elm-datepicker--disabled {
  color: var(--color-primary-100);
}

.elm-datepicker--other-month.elm-datepicker--picked {
  color: #fff;
}

.elm-datepicker--months td, .elm-datepicker--years td {
  padding: 1em 1.5em;
  font-size: 1.1em;
}

.toggle {
  cursor: pointer;
  border-style: solid;
  border-width: 1px;
  border-color: var(--color-primary-200);
  border-radius: .125rem;
  width: 5rem;
  height: 34px;
  margin-bottom: 0;
  display: block;
  position: relative;
}

[components-theme="wip"] .toggle {
  border-style: none;
  border-radius: 9999px;
  width: 4rem;
}

.toggle__background {
  z-index: 0;
  border-radius: .125rem;
  width: 100%;
  height: 100%;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: block;
}

[components-theme="wip"] .toggle__background {
  border-radius: 9999px;
}

.toggle__background {
  background-color: var(--color-black);
  justify-content: space-between;
  align-items: center;
  padding-left: .75rem;
  padding-right: .75rem;
  display: flex;
}

input:checked ~ .toggle__background {
  background-color: var(--color-primary-300);
}

.toggle__knob {
  z-index: 10;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  width: 50%;
  height: 100%;
  transition-property: all;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: block;
  position: absolute;
}

[components-theme="wip"] .toggle__knob {
  border-radius: 9999px;
  width: 1.75rem;
  height: 1.75rem;
}

.toggle__knob {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

[components-theme="wip"] .toggle__knob {
  left: .25rem;
}

input:checked ~ .toggle__knob {
  left: 50%;
}

.toggle > input {
  display: none;
}

.toggle--sm {
  width: 1.75rem;
  height: 1rem;
}

[components-theme="wip"] .toggle--sm {
  width: 1.75rem;
}

.toggle--sm > .toggle__background {
  border-radius: 0;
}

[components-theme="wip"] .toggle--sm > .toggle__background {
  border-radius: 9999px;
}

[components-theme="wip"] .toggle--sm > .toggle__knob {
  width: .75rem;
  height: .75rem;
  left: .125rem;
}

.toggle--sm > input:checked ~ .toggle__knob {
  left: 50%;
}

.toggle--sm > .toggle__icon {
  display: none;
}

.toggle--disabled {
  cursor: not-allowed;
}

[components-theme="wip"] .toggle--disabled > .toggle__background, [components-theme="wip"] .toggle--disabled > input:checked ~ .toggle__background {
  background-color: var(--color-primary-200);
}

.toggle--disabled > .toggle__knob {
  background-color: var(--color-primary-500);
}

[components-theme="wip"] .toggle--disabled > .toggle__knob {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.toggle__icon {
  fill: #fff;
  height: .75rem;
}

.dropdown .inline, .dropup .inline {
  display: inline-block;
}

.dropdown > .button--default:not([disabled]):hover, .dropdown > .button--default:not([disabled]):focus, .dropdown > .button--default:not([disabled]):active, .dropup > .button--default:not([disabled]):hover, .dropup > .button--default:not([disabled]):focus, .dropup > .button--default:not([disabled]):active {
  border-color: var(--color-primary-200);
  color: var(--color-black);
}

.dropdown + .dropdown, .button + .dropdown, .link + .dropdown {
  margin-left: .5rem;
}

.dropdown.disabled {
  cursor: not-allowed;
  pointer-events: none;
}

.dropdown-menu > .simpler-dropdown__item, .dropdown-menu__item {
  cursor: pointer;
}

.dropdown-menu > .simpler-dropdown__item > *, .dropdown-menu__item > * {
  clear: both;
  color: var(--color-black);
  white-space: nowrap;
  padding: 3px 20px;
  font-weight: 400;
  line-height: 1.42857;
  display: block;
}

.dropdown-menu > .simpler-dropdown__item > :hover, .dropdown-menu__item > :hover {
  color: #262626;
  background-color: var(--color-primary-50);
}

.dropdown-menu > .simpler-dropdown__item > :active, .dropdown-menu__item > :active {
  outline: 0;
}

.dropdown-menu > .active.simpler-dropdown__item > *, .dropdown-menu__item.active > * {
  color: #fff;
  background-color: var(--color-primary-300);
}

.dropdown-menu > .disabled.simpler-dropdown__item > *, .dropdown-menu__item.disabled > * {
  color: var(--color-primary-500);
}

.flyout-menu-wrapper {
  display: inline-block;
  position: relative;
}

.flyout-menu__caret {
  z-index: 1020;
  margin-left: -8px;
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
}

.flyout-menu__caret:after {
  border-color: #0000 #fff #0000 #0000;
}

.flyout-menu__caret:before {
  border-color: #0000 #e3e3e3 #0000 #0000;
  right: -20px;
}

.flyout-menu__caret:before, .flyout-menu__caret:after {
  z-index: 1020;
  content: "";
  border-style: solid;
  border-width: 11px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.flyout-menu {
  z-index: 10;
  background-color: #fff;
  border: 1px solid #e3e3e3;
  width: 270px;
  max-height: 50vh;
  padding: 1em 1em 1em 1.3em;
  position: absolute;
  top: calc(-8% - 1px);
  overflow-y: auto;
  box-shadow: 0 0 2px #0000000d, 0 2px 8px #00000014;
}

.flyout-menu::-webkit-scrollbar {
  width: 25px;
}

.flyout-menu::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px 10px var(--color-primary-100);
  border: 10px solid #0000;
  border-radius: 25px;
}

.flyout-menu::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 10px 10px var(--color-primary-200);
  border: 10px solid #0000;
  border-radius: 25px;
}

.flyout-menu--fixed {
  z-index: 1020;
  position: fixed;
}

.flyout-menu__content {
  flex-direction: column;
  display: flex;
}

.flyout-menu__header {
  padding: .8em 1em .6em;
}

.flyout-menu__header h4 {
  color: #000;
  font-size: 16px;
  font-weight: 700;
}

.flyout-menu--right {
  left: 100%;
}

.flyout-menu--right.flyout-menu--fixed {
  max-height: 80vh;
  top: 10vh;
  left: calc(3.7rem - 1px);
}

svg.icon {
  vertical-align: middle;
  height: .875rem;
  margin-top: -3px;
  transition-property: all;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-block;
}

svg.icon--xs {
  height: .625rem;
}

svg.icon--sm {
  height: .75rem;
}

svg.icon--lg {
  height: 1rem;
}

svg.icon--xl {
  height: 1.25rem;
}

svg.icon--danger {
  color: var(--color-danger-300);
}

svg.icon--info {
  color: var(--color-primary-500);
}

svg.icon--primary {
  color: var(--color-primary-300);
}

svg.icon + svg.icon, .link + svg.icon {
  margin-left: .5rem;
}

svg.icon.rotate-180 {
  transform: rotate(180deg);
}

.icon-xs {
  font-size: .75rem;
}

.icon-sm {
  font-size: 1rem;
}

.icon-md {
  font-size: 1.25rem;
}

.icon-lg {
  font-size: 1.75rem;
}

.icon__copy {
  height: .75rem;
  margin-top: .25rem;
  padding-left: .75rem;
}

.icon__btn-link {
  vertical-align: middle;
  width: 30px;
  height: 14px;
}

.modal.show {
  overflow-y: scroll;
}

.elm-modal {
  -webkit-overflow-scrolling: touch;
  z-index: 1040;
  outline: 0;
  position: fixed;
  inset: 0;
  overflow: hidden auto;
}

.elm-modal .modal-dialog {
  z-index: 1050;
}

@media (width >= 992px) {
  .elm-modal {
    width: auto;
  }
}

.elm-modal.modal-lg {
  width: 900px;
}

.elm-modal.modal-sm {
  width: 300px;
}

.modal-header {
  position: relative;
}

.modal-header .button.close {
  text-align: right;
  font-size: 1.25rem;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}

.highlight-phi [data-recording-sensitive], .highlight-phi [data-recording-ignore="mask"] {
  color: red;
  background-color: red;
}

.popover__wrapper {
  display: inline-block;
  position: relative;
}

.popover__wrapper.visible .popover__content, .popover__wrapper.hoverable:hover .popover__content {
  z-index: 10;
  opacity: 1;
  visibility: visible;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.popover__wrapper:after {
  content: "";
  padding: 1rem;
  position: absolute;
  top: -60%;
  transform: translateX(-40%);
}

.popover__wrapper + .popover__wrapper, .button + .popover__wrapper, .link + .popover__wrapper, .badge + .popover__wrapper {
  margin-left: .5rem;
}

.popover__content {
  visibility: hidden;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  opacity: 0;
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  width: auto;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  filter: drop-shadow(0 0 2px #0003);
  border-radius: .25rem;
  min-width: 300px;
  font-size: .875rem;
  line-height: 1.25rem;
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
}

.popover__content.popover--lg {
  min-width: 600px;
  max-width: 775px;
}

.popover__content.popover--md {
  min-width: 380px;
  max-width: 400px;
  max-height: 400px;
  overflow-y: scroll;
}

.popover__content.popover--sm {
  min-width: 230px;
  max-width: 250px;
}

.popover__body:first-child:before, .popover__content:before {
  z-index: -1;
  content: "";
  border-color: transparent transparent var(--color-primary-150) transparent;
  border-style: solid;
  border-width: 0 10px 10px;
  transition-property: transform;
  transition-duration: .3s;
  position: absolute;
  top: -8px;
  right: calc(50% - 10px);
}

.popover__body:first-child:before {
  border-color: transparent transparent var(--color-white) transparent;
}

.popover__heading {
  font-weight: bold;
}

.popover__heading, .popover__footer {
  background-color: var(--color-primary-150);
}

.popover__heading, .popover__body, .popover__footer {
  padding: .75rem;
  position: relative;
}

.popover--dark {
  background-color: var(--color-black);
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.popover--dark:before {
  border-color: #0000 #0000 #000;
}

.popover--dark.bottom:before {
  border-bottom-color: #000;
}

.popover--dark .popover__heading, .popover--dark .popover__footer {
  background-color: var(--color-black);
}

.popover--dark .popover__body:first-child:before {
  border-color: transparent transparent var(--color-black) transparent;
}

.ui-select-container .ui-select-toggle .spinner {
  height: 10px;
  margin-top: -5px;
  position: absolute;
  top: 50%;
  right: 10px;
}

.ui-select-container .btn-default.ui-select-toggle:hover {
  border-color: var(--color-primary-200);
}

.ui-select-container .btn-default.btn-disabled {
  pointer-events: none;
  opacity: .65;
  box-shadow: none;
}

.tooltip-wrapper {
  display: inline-block;
  position: relative;
}

.tooltip {
  text-transform: none;
  position: absolute;
}

.tooltip:before {
  content: attr(data-text);
  color: #fff;
  text-align: center;
  z-index: 100;
  background: #000c;
  border-radius: 3px;
  padding: .75rem;
  font-size: .875rem;
  font-weight: 400;
  display: none;
  position: absolute;
}

.tooltip.z-100:before, .tooltip.z-100:after {
  z-index: 100;
}

.tooltip.z-1030:before, .tooltip.z-1030:after {
  z-index: 1000;
}

.tooltip:after {
  content: "";
  border: 10px solid #000;
  display: none;
  position: absolute;
}

.tooltip-wrapper:hover > .tooltip:after, .tooltip-wrapper:hover > .tooltip:before {
  display: block;
}

.tooltip--right, .tooltip--left {
  top: 50%;
  transform: translateY(-50%);
}

.tooltip--right {
  left: 100%;
}

.tooltip--right:before {
  border-color: #0000 #000c #0000 #0000;
  margin-left: 10px;
}

.tooltip--right:after {
  border-color: #0000 #000c #0000 #0000;
  margin-left: -10px;
}

.tooltip--left {
  left: 0;
}

.tooltip--left:before {
  margin-right: .75rem;
  right: 100%;
}

.tooltip--left:after {
  border-color: #0000 #0000 #0000 #000c;
  margin-right: -.5rem;
  right: 100%;
}

.tooltip--top, .tooltip--bottom {
  min-width: 12rem;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip--top.tooltip--wide, .tooltip--bottom.tooltip--wide {
  min-width: 30rem;
}

.tooltip--top {
  top: 0;
}

.tooltip--top:before {
  margin-bottom: .75rem;
  bottom: 100%;
}

.tooltip--top:after {
  border-color: #000c #0000 #0000;
  margin-bottom: -.5rem;
  bottom: 100%;
}

.tooltip--bottom {
  top: 100%;
}

.tooltip--bottom:before {
  margin-top: .75rem;
  top: 100%;
}

.tooltip--bottom:after {
  border-color: #0000 #0000 #000c;
  margin-top: -.5rem;
  top: 100%;
}

.tooltip--left:before, .tooltip--right:before, .tooltip--left:after, .tooltip--right:after {
  top: 50%;
  transform: translateY(-50%);
}

.tooltip--top:before, .tooltip--bottom:before, .tooltip--top:after, .tooltip--bottom:after {
  left: 50%;
  transform: translateX(-50%);
}

.button + .tooltip-wrapper, .tooltip-wrapper + .tooltip-wrapper, .link + .tooltip-wrapper {
  margin-left: .5rem;
}

.underline-tabs {
  border-bottom: 1px solid #c4dcf8;
  margin-bottom: .75rem;
  display: flex;
  position: relative;
  overflow-x: scroll;
}

.underline-tabs::-webkit-scrollbar {
  display: none;
}

.underline-tabs__current {
  background-color: var(--color-primary-300);
  transform-origin: 0;
  width: 1px;
  height: 2px;
  transition: all .3s ease-out;
  position: absolute;
  bottom: 0;
  left: 0;
}

.underline-tabs__item {
  color: var(--color-primary-500);
  background-color: inherit;
  white-space: nowrap;
  border: none;
  padding: .5rem .25rem;
  font-size: 14px;
}

.underline-tabs__item:not(:last-child) {
  margin-right: 1.25rem;
}

.underline-tabs__item:focus, .underline-tabs__item:active {
  color: var(--color-primary-300);
  outline: none;
  transition: all .3s ease-out;
}

.underline-tabs__item--active, .underline-tabs__item--active:focus, .underline-tabs__item--active:active {
  pointer-events: none;
  color: var(--color-primary-300);
  cursor: auto;
}

.underline-tabs__item--disabled {
  pointer-events: none;
}

.modal-header > .underline-tabs {
  border: none;
  margin-top: 6px;
  margin-bottom: -15px;
}

.list-group-item.list-group-item__header {
  background-color: var(--color-primary-100);
  color: #979797;
  font-size: 12px;
  font-weight: 500;
}

.list-group-item.search-result {
  border: 0;
  border-radius: 4px;
  padding: 15px;
}

.input-group {
  flex: 1;
  align-items: flex-start;
  display: flex;
}

.input-group > .input-group-btn:not(:last-child) > .dropdown > .button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  height: 34px;
}

.input-group > * {
  margin-right: -1px;
}

.input-group > :focus {
  z-index: 10;
}

.input-group > .input-group-btn:hover {
  z-index: 3;
}

.input-group__addon {
  white-space: nowrap;
  border-style: solid;
  border-width: 1px;
  border-color: var(--color-primary-200);
  background-color: var(--color-primary-100);
  padding: 6px 12px;
}

.input-group__addon:last-child {
  border-left-width: 0;
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.input-group__addon:first-child {
  border-right-width: 0;
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.input-group > .elm-datepicker--container, .input-group > .input-wrapper {
  flex: 1;
}

.input-group > .elm-datepicker--container:not(:first-child) > .elm-datepicker--input, .input-group > .input-wrapper:not(:first-child) > .form-control, .input-group > .dropdown:not(:first-child) > .button, .input-group > .button:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .elm-datepicker--container:not(:last-child) > .elm-datepicker--input, .input-group > .input-wrapper:not(:last-child) > .form-control, .input-group > .dropdown:not(:last-child) > .button, .input-group > .button:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .button + .button {
  margin-left: 0;
}

.pagination {
  border-style: solid;
  border-width: 1px;
  border-color: var(--color-primary-200);
  color: var(--color-primary-300);
  border-radius: .25rem;
  padding: 0;
  list-style-type: none;
  display: flex;
}

[components-theme="wip"] .pagination {
  border-style: none;
}

.pagination__item {
  cursor: pointer;
  border-style: solid;
  border-width: 0 0 0 1px;
  border-color: var(--color-primary-200);
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  padding: .25rem .75rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

[components-theme="wip"] .pagination__item {
  border-style: solid;
  border-width: 1px;
  border-color: var(--color-primary-150);
  border-radius: .25rem;
  margin-right: .5rem;
}

.pagination__item:not(:is(.active, .disabled)):hover {
  background-color: var(--color-primary-50);
}

.pagination__item.active {
  cursor: auto;
  background-color: var(--color-primary-300);
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

[components-theme="wip"] .pagination__item.active {
  border-color: var(--color-primary-300);
}

.pagination__item.active {
  pointer-events: none;
}

.pagination__item.disabled {
  cursor: not-allowed;
  color: var(--color-black);
  pointer-events: none;
}

.pagination__item:first-child {
  border-left-width: 0;
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

[components-theme="wip"] .pagination__item:first-child {
  border-left-width: 1px;
}

.pagination__item:last-child {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.pagination--sm > .pagination__item {
  padding: .125rem .5rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.pagination__total {
  color: var(--color-primary-500);
}

.breadcrumb {
  background-color: #0000;
  margin-bottom: 1.25rem;
  padding: 0;
  list-style-type: none;
}

.breadcrumb > li {
  display: inline-block;
}

.breadcrumb > li:not(:first-child):before {
  color: var(--color-primary-200);
  content: "/";
  padding-left: .5rem;
  padding-right: .5rem;
}

.breadcrumb > .active {
  color: var(--color-primary-500);
}

[components-theme="wip"] .breadcrumb > .active {
  color: var(--color-black);
}

.alert {
  text-align: center;
  border: 1px solid #0000;
  border-radius: .25rem;
  align-items: flex-start;
  padding: .75rem;
  display: flex;
}

.alert--icon > svg {
  fill: currentColor;
  background-image: none;
  border-style: none;
  max-width: 6em;
  max-height: 6em;
  margin-right: 1rem;
}

.alert--contents {
  flex: 1;
}

.alert--primary {
  border-color: var(--color-primary-200);
  background-color: var(--color-primary-100);
  color: var(--color-primary-300);
}

.alert--info {
  border-color: var(--color-primary-200);
  background-color: var(--color-primary-100);
  color: var(--color-primary-500);
}

.alert--danger {
  border-color: var(--color-danger-200);
  background-color: var(--color-danger-100);
  color: var(--color-danger-300);
}

.alert--warning {
  border-color: var(--color-warning-200);
  background-color: var(--color-warning-100);
  color: var(--color-warning-400);
}

.alert--success {
  border-color: var(--color-success-200);
  background-color: var(--color-success-100);
  color: var(--color-success-400);
}

.alert__dismiss.button {
  background-image: none;
  border-style: none;
  padding: .25rem .25rem 0;
}

.alert__dismiss.button > svg {
  width: 1rem;
  height: 1rem;
  fill: var(--color-gray);
}

.flex-row {
  flex-wrap: wrap;
  align-items: flex-start;
  margin-bottom: -.5rem;
  margin-left: -.25rem;
  margin-right: -.25rem;
  display: flex;
}

.flex-row--gutter-md {
  margin-left: -1rem;
  margin-right: -1rem;
}

.flex-row + .flex-row {
  margin-top: .5rem;
}

.flex-column {
  flex-direction: column;
  width: 100%;
  margin-bottom: .5rem;
  padding-left: .25rem;
  padding-right: .25rem;
  display: flex;
}

.flex-row--gutter-md > .flex-column {
  padding-left: .5rem;
  padding-right: .5rem;
}

.flex-row--no-gutter > .flex-column {
  padding-left: 0;
  padding-right: 0;
}

:root {
  --timeline-items-spacing: 10px;
  --timeline-bullet-size: 11px;
  --timeline-bullet-top: 4px;
  --timline-line-thickness: 1px;
}

.timeline {
  padding: 0;
  list-style-type: none;
}

.timeline__item {
  margin-bottom: var(--timeline-items-spacing);
  padding-left: 1.5rem;
  position: relative;
}

.timeline__item:before {
  content: "";
  z-index: 10;
  background-color: var(--color-primary-150);
  left: 0;
  top: var(--timeline-bullet-top);
  width: var(--timeline-bullet-size);
  height: var(--timeline-bullet-size);
  border-radius: 9999px;
  position: absolute;
}

.timeline__item:after {
  background-color: var(--color-primary-150);
  z-index: 0;
  left: calc(calc(var(--timeline-bullet-size)  - 1px) / 2);
  top: var(--timeline-bullet-top);
  height: var(--timeline-bullet-top);
  width: var(--timline-line-thickness);
  position: absolute;
}

.timeline__item:not(:first-child):after {
  top: 0;
}

.timeline__item:not(:last-child):after {
  content: "";
  height: auto;
  bottom: calc(-1 * var(--timeline-items-spacing)  - var(--timeline-bullet-top));
}

.timeline__item--error {
  color: var(--color-danger-300);
}

.timeline__item--error:before {
  background-color: var(--color-danger-300);
}

@media (width >= 992px) {
  .timeline--horizontal {
    justify-content: flex-start;
    display: flex;
  }

  .timeline--horizontal > .timeline__item {
    padding-top: 1.25rem;
    padding-left: 0;
    padding-right: 1.25rem;
  }

  .timeline--horizontal > .timeline__item:before {
    top: 0;
  }

  .timeline--horizontal > .timeline__item:after {
    width: 100%;
    height: var(--timline-line-thickness);
    top: calc(var(--timeline-bullet-size) / 2);
  }

  .timeline--horizontal > .timeline__item {
    max-width: 10rem;
  }
}

.timeline--lg {
  --timeline-bullet-size: 1rem;
  --timline-line-thickness: 2px;
}

.new-dropdown {
  position: relative;
}

.new-dropdown__trigger {
  transition-property: all;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.new-dropdown__trigger--select {
  font: inherit;
  cursor: pointer;
  outline-offset: 2px;
  border-style: solid;
  border-width: 1px;
  border-color: var(--color-primary-200);
  --tw-bg-opacity: 1;
  background: none;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  text-align: left;
  width: 100%;
  height: 34px;
  color: var(--color-black);
  border-radius: .25rem;
  outline: 2px solid #0000;
  padding-left: .75rem;
  padding-right: .75rem;
  display: block;
}

.new-dropdown__trigger--select + .new-dropdown-menu {
  width: 100%;
}

.new-dropdown__trigger--select:focus, .new-dropdown--open > .new-dropdown__trigger--select {
  box-shadow: inset 0 1px 1px #00000013, 0 0 8px #66afe999;
}

.new-dropdown__trigger__chevron > svg {
  margin-top: 0;
  transition-property: all;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.new-dropdown--open .new-dropdown__trigger__chevron > svg {
  transform: rotate(180deg);
}

.new-dropdown--invalid .new-dropdown__trigger {
  border-color: var(--color-danger-300);
}

.new-dropdown-menu {
  scrollbar-color: var(--color-primary-150) var(--color-primary-150);
  min-width: 200px;
  box-shadow: 0 7px 29px #64646f33;
}

.new-dropdown--open > .new-dropdown-menu {
  display: block;
}

.new-dropdown-menu {
  z-index: 50;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  border-radius: .25rem;
  padding: .5rem;
  list-style-type: none;
  display: none;
  position: absolute;
  top: calc(100% + .25rem);
}

.new-dropdown-menu--dir-rd {
  top: -.5rem;
  left: calc(100% + .5rem);
}

.new-dropdown-menu--dir-dl {
  right: 0;
}

.new-dropdown-menu--overflow-scroll {
  max-height: min(50vh, 420px);
  overflow: scroll;
}

.new-dropdown-menu__item {
  cursor: pointer;
  border-radius: .25rem;
  margin: 1px -.25rem;
  padding: .375rem;
}

.new-dropdown-menu__item:hover, .new-dropdown-menu__item:focus-within {
  background-color: var(--color-primary-100);
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.new-dropdown-menu__item--active, .new-dropdown-menu__item--active:hover, .new-dropdown-menu__item--active:focus-within {
  background-color: var(--color-primary-300);
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.new-dropdown-menu__item--checkbox {
  padding: 0;
}

.new-dropdown-menu__item--checkbox > label {
  cursor: pointer;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: .375rem;
  font-weight: 400;
  display: flex;
}

.new-dropdown-menu__item--checkbox input[type="checkbox"] {
  cursor: pointer;
  margin-top: 0;
  margin-right: .5rem;
}

.new-dropdown-menu__item--input > input {
  color: var(--color-primary-400);
  background: none;
  border-width: 0;
  margin: 0;
  padding: 0;
}

.new-dropdown-menu__item--input > input::placeholder {
  color: var(--color-primary-400);
}

.new-dropdown-menu__item--input > input:focus, .new-dropdown-menu__item--input > input:active {
  outline: none;
}

.new-dropdown-menu__item--separator {
  cursor: default;
  margin-left: -.5rem;
  margin-right: -.5rem;
  padding: .5rem 0;
}

.new-dropdown-menu__item--separator:after {
  background-color: var(--color-primary-200);
  content: "";
  width: 100%;
  height: 1px;
  display: block;
}

.new-dropdown-menu__item--separator:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.new-dropdown-menu__item--inner {
  position: relative;
}

.new-dropdown-menu__item--inner > button.new-dropdown__trigger {
  font: inherit;
  cursor: pointer;
  outline-offset: 2px;
  background: none;
  border-style: none;
  outline: 2px solid #0000;
  width: 100%;
  margin-top: -.5rem;
  margin-bottom: -.5rem;
  padding: .5rem 0;
}

.new-dropdown-menu__item--link > a {
  color: inherit;
}

.new-dropdown-menu__item--link > a:focus, .new-dropdown-menu__item--link > a:active {
  outline-offset: 2px;
  outline: 2px solid #0000;
  text-decoration-line: none;
}

.z-0 {
  z-index: 0;
}

.z-100 {
  z-index: 100;
}

.z-1030 {
  z-index: 1030;
}

.h-100 {
  height: 100% !important;
}

.stretched-link:after {
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: #0000;
  position: absolute;
  inset: 0;
}

@font-face {
  font-family: Glyphicons Halflings;
  src: url("glyphicons-halflings-regular.cca02454.eot");
  src: url("glyphicons-halflings-regular.cca02454.eot#iefix") format("embedded-opentype"), url("glyphicons-halflings-regular.a3cea8f3.woff2") format("woff2"), url("glyphicons-halflings-regular.3a458e4a.woff") format("woff"), url("glyphicons-halflings-regular.1a33c1eb.ttf") format("truetype"), url("glyphicons-halflings-regular.d7c538a3.svg#glyphicons_halflingsregular") format("svg");
}

.glyphicon {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  display: inline-block;
  position: relative;
  top: 1px;
  font-family: Glyphicons Halflings !important;
}

.glyphicon-plus:before {
  content: "+";
}

.glyphicon-alert:before {
  content: "";
}

.glyphicon-arrow-right:before {
  content: "";
}

.glyphicon-arrow-left:before {
  content: "";
}

.glyphicon-ok:before {
  content: "";
}

.glyphicon-envelope:before {
  content: "✉";
}

.glyphicon-pencil:before {
  content: "✏";
}

.glyphicon-erase:before {
  content: "";
}

.glyphicon-remove:before {
  content: "";
}

.glyphicon-flag:before {
  content: "";
}

.glyphicon-link:before {
  content: "";
}

.glyphicon-refresh:before {
  content: "";
}

.glyphicon-minus:before {
  content: "−";
}

.glyphicon-minus-sign:before {
  content: "";
}

.glyphicon-new-window:before {
  content: "";
}

.glyphicon-ok-circle:before {
  content: "";
}

.glyphicon-list-alt:before {
  content: "";
}

.glyphicon-plus-sign:before {
  content: "";
}

.glyphicon-print:before {
  content: "";
}

.glyphicon-chevron-left:before {
  content: "";
}

.glyphicon-chevron-right:before {
  content: "";
}

.glyphicon-chevron-up:before {
  content: "";
}

.glyphicon-chevron-down:before {
  content: "";
}

.glyphicon-search:before {
  content: "";
}

.glyphicon-ban-circle:before {
  content: "";
}

.glyphicon-warning-sign:before {
  content: "";
}

.glyphicon-wrench:before {
  content: "";
}

.glyphicon-shopping-cart:before {
  content: "";
}

.glyphicon-duplicate:before {
  content: "";
}

.glyphicon-earphone:before {
  content: "";
}

.glyphicon-question-sign:before {
  content: "";
}

.glyphicon-user:before {
  content: "";
}

.glyphicon-cloud-download:before {
  content: "";
}

.glyphicon-lock:before {
  content: "";
}

.glyphicon-file:before {
  content: "";
}

.glyphicon-cloud-upload:before {
  content: "";
}

.glyphicon-calendar:before {
  content: "";
}

.glyphicon-flash:before {
  content: "";
}

.glyphicon-export:before {
  content: "";
}

.glyphicon-menu-down:before {
  content: "";
}

.glyphicon-menu-up:before {
  content: "";
}

.glyphicon-triangle-top:before {
  content: "";
}

.glyphicon-triangle-bottom:before {
  content: "";
}

.glyphicon-tasks:before {
  content: "";
}

.glyphicon-gift:before {
  content: "";
}

.glyphicon-send:before {
  content: "";
}

.glyphicon-eye-open:before {
  content: "";
}

.glyphicon-eye-close:before {
  content: "";
}

.glyphicon-copy:before {
  content: "";
}

.glyphicon-retweet:before {
  content: "";
}

.glyphicon-random:before {
  content: "";
}

.glyphicon-arrow-down:before {
  content: "";
}

.glyphicon-arrow-up:before {
  content: "";
}

.grecaptcha-badge {
  visibility: hidden;
}

.patients-list--mini {
  display: none;
}

@media (width >= 992px) {
  .patients-list--mini {
    display: block;
  }
}

.patients-list--mini {
  width: 33.3333%;
  margin-right: 1rem;
}

[components-theme="wip"] .patients-list--mini {
  border-style: solid;
  border-width: 0 1px 0 0;
  border-color: var(--color-primary-150);
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  width: calc(16rem + 30px);
  top: 0;
  bottom: 0;
  top: var(--total-header-height);
  margin-top: 0;
  margin-left: -30px;
  margin-right: -10px;
  padding: 1rem 1rem 0;
  position: fixed;
  overflow: hidden scroll;
}

[components-theme="wip"] .patients-list--mini::-webkit-scrollbar {
  display: none;
}

[components-theme="wip"] .patients-list--mini .panel-body:first-child {
  padding-left: 0;
  padding-right: 0;
}

.patients-list--mini .table-cell--active {
  border-width: 0 0 0 2px;
  border-color: var(--color-primary-500);
  background-color: var(--color-primary-100);
  padding-left: .5rem;
}

.patients-list--mini .table-cell--active, .patients-list--mini .table-cell--active .text-muted {
  color: var(--color-primary-300);
}

.patients-list--mini .table-cell--active a {
  font-weight: 600;
}

.patients-list--mini .table-cell--active .badge {
  background-color: var(--color-white);
}

[components-theme="wip"] .patients-list--mini .panel {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-width: 0;
  margin-bottom: 0;
}

[components-theme="wip"] .patients-list--mini .panel-body:last-child {
  padding: 0;
}

.patients-list--mini.patients-list--phi-mode > .panel > .table-wrapper {
  visibility: hidden;
  transition-property: all;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

[components-theme="wip"] .patients-list--mini .table-wrapper {
  width: 100%;
  margin: 0;
}

a * {
  pointer-events: none;
}

.form-control__feedback {
  margin-top: 8px;
  position: absolute;
  right: 0;
}

.container {
  max-width: 100vw;
}

.simpler-dropdown {
  display: inline-block;
}

.simpler-dropdown__item > a > svg {
  fill: currentColor;
  width: .75rem;
  height: .75rem;
  margin-bottom: -1px;
  margin-right: .5rem;
}

.simpler-dropdown .simpler-dropdown__item > a, .simpler-dropdown .simpler-dropdown__item > a:hover {
  color: currentColor;
}

.simpler-dropdown--round .button--sm {
  border-radius: 9999px;
  padding-left: .25rem;
  padding-right: .25rem;
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.visible {
  visibility: visible;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.relative {
  position: relative;
}

.row-auto {
  grid-row: auto;
}

.m-0 {
  margin: 0;
}

.-mb-0\.5 {
  margin-bottom: -.125rem;
}

.-mb-8 {
  margin-bottom: -2rem;
}

.-ml-1\/12 {
  margin-left: -8.33%;
}

.-ml-10\/12 {
  margin-left: -83.33%;
}

.-ml-11\/12 {
  margin-left: -91.66%;
}

.-ml-2\/12 {
  margin-left: -16.66%;
}

.-ml-3\/12 {
  margin-left: -25%;
}

.-ml-4\/12 {
  margin-left: -33.33%;
}

.-ml-5\/12 {
  margin-left: -41.66%;
}

.-ml-6\/12 {
  margin-left: -50%;
}

.-ml-7\/12 {
  margin-left: -58.333%;
}

.-ml-8\/12 {
  margin-left: -66.66%;
}

.-ml-9\/12 {
  margin-left: -75%;
}

.-ml-full {
  margin-left: -100%;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-1\/12 {
  margin-left: 8.33%;
}

.ml-10\/12 {
  margin-left: 83.33%;
}

.ml-11\/12 {
  margin-left: 91.66%;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-2\/12 {
  margin-left: 16.66%;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-3\/12 {
  margin-left: 25%;
}

.ml-4\/12 {
  margin-left: 33.33%;
}

.ml-5\/12 {
  margin-left: 41.66%;
}

.ml-6\/12 {
  margin-left: 50%;
}

.ml-7\/12 {
  margin-left: 58.333%;
}

.ml-8\/12 {
  margin-left: 66.66%;
}

.ml-9\/12 {
  margin-left: 75%;
}

.ml-auto {
  margin-left: auto;
}

.ml-full {
  margin-left: 100%;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-8 {
  margin-right: 2rem;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.table-cell {
  display: table-cell;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.h-full {
  height: 100%;
}

.w-1\/12 {
  width: 8.33333%;
}

.w-1\/2 {
  width: 50%;
}

.w-10\/12 {
  width: 83.3333%;
}

.w-11\/12 {
  width: 91.6667%;
}

.w-2\/12 {
  width: 16.6667%;
}

.w-3\/12 {
  width: 25%;
}

.w-4\/12 {
  width: 33.3333%;
}

.w-5\/12 {
  width: 41.6667%;
}

.w-6\/12 {
  width: 50%;
}

.w-7\/12 {
  width: 58.3333%;
}

.w-72 {
  width: 18rem;
}

.w-8\/12 {
  width: 66.6667%;
}

.w-9\/12 {
  width: 75%;
}

.w-auto {
  width: auto;
}

.w-full {
  width: 100%;
}

.min-w-full {
  min-width: 100%;
}

.max-w-full {
  max-width: 100%;
}

.max-w-sm {
  max-width: 24rem;
}

.flex-1 {
  flex: 1;
}

.flex-auto {
  flex: auto;
}

.flex-initial {
  flex: 0 auto;
}

.flex-none {
  flex: none;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.flex-grow-0 {
  flex-grow: 0;
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.list-none {
  list-style-type: none;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.25rem * var(--tw-space-x-reverse));
  margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.break-all {
  word-break: break-all;
}

.rounded {
  border-radius: .25rem;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-hidden {
  border-style: hidden;
}

.bg-primary-50 {
  background-color: var(--color-primary-50);
}

.p-0 {
  padding: 0;
}

.p-6 {
  padding: 1.5rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pl-0 {
  padding-left: 0;
}

.pl-1 {
  padding-left: .25rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top: .25rem;
}

.pt-1\.5 {
  padding-top: .375rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-normal {
  font-weight: 400;
}

.lowercase {
  text-transform: lowercase;
}

.italic {
  font-style: italic;
}

.ordinal {
  --tw-ordinal: ordinal;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}

.text-black {
  color: var(--color-black);
}

.text-current {
  color: currentColor;
}

.text-danger {
  color: var(--color-danger-300);
}

.text-gray {
  color: var(--color-gray);
}

.text-info {
  color: var(--color-info-300);
}

.text-primary, .text-primary-300 {
  color: var(--color-primary-300);
}

.text-success {
  color: var(--color-success-300);
}

.text-warning {
  color: var(--color-warning-300);
}

.underline {
  text-decoration-line: underline;
}

.ring {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

@media (width >= 768px) {
  .sm\:row-auto {
    grid-row: auto;
  }

  .sm\:-ml-1\/12 {
    margin-left: -8.33%;
  }

  .sm\:-ml-10\/12 {
    margin-left: -83.33%;
  }

  .sm\:-ml-11\/12 {
    margin-left: -91.66%;
  }

  .sm\:-ml-2\/12 {
    margin-left: -16.66%;
  }

  .sm\:-ml-3\/12 {
    margin-left: -25%;
  }

  .sm\:-ml-4\/12 {
    margin-left: -33.33%;
  }

  .sm\:-ml-5\/12 {
    margin-left: -41.66%;
  }

  .sm\:-ml-6\/12 {
    margin-left: -50%;
  }

  .sm\:-ml-7\/12 {
    margin-left: -58.333%;
  }

  .sm\:-ml-8\/12 {
    margin-left: -66.66%;
  }

  .sm\:-ml-9\/12 {
    margin-left: -75%;
  }

  .sm\:-ml-full {
    margin-left: -100%;
  }

  .sm\:ml-1\/12 {
    margin-left: 8.33%;
  }

  .sm\:ml-10\/12 {
    margin-left: 83.33%;
  }

  .sm\:ml-11\/12 {
    margin-left: 91.66%;
  }

  .sm\:ml-2\/12 {
    margin-left: 16.66%;
  }

  .sm\:ml-3\/12 {
    margin-left: 25%;
  }

  .sm\:ml-4\/12 {
    margin-left: 33.33%;
  }

  .sm\:ml-5\/12 {
    margin-left: 41.66%;
  }

  .sm\:ml-6\/12 {
    margin-left: 50%;
  }

  .sm\:ml-7\/12 {
    margin-left: 58.333%;
  }

  .sm\:ml-8\/12 {
    margin-left: 66.66%;
  }

  .sm\:ml-9\/12 {
    margin-left: 75%;
  }

  .sm\:ml-auto {
    margin-left: auto;
  }

  .sm\:ml-full {
    margin-left: 100%;
  }

  .sm\:block {
    display: block;
  }

  .sm\:inline-block {
    display: inline-block;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:inline-flex {
    display: inline-flex;
  }

  .sm\:table-cell {
    display: table-cell;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:w-1\/12 {
    width: 8.33333%;
  }

  .sm\:w-10\/12 {
    width: 83.3333%;
  }

  .sm\:w-11\/12 {
    width: 91.6667%;
  }

  .sm\:w-2\/12 {
    width: 16.6667%;
  }

  .sm\:w-3\/12 {
    width: 25%;
  }

  .sm\:w-4\/12 {
    width: 33.3333%;
  }

  .sm\:w-5\/12 {
    width: 41.6667%;
  }

  .sm\:w-6\/12 {
    width: 50%;
  }

  .sm\:w-7\/12 {
    width: 58.3333%;
  }

  .sm\:w-8\/12 {
    width: 66.6667%;
  }

  .sm\:w-9\/12 {
    width: 75%;
  }

  .sm\:w-auto {
    width: auto;
  }

  .sm\:w-full {
    width: 100%;
  }

  .sm\:min-w-full {
    min-width: 100%;
  }

  .sm\:max-w-full {
    max-width: 100%;
  }

  .sm\:flex-1 {
    flex: 1;
  }

  .sm\:flex-auto {
    flex: auto;
  }

  .sm\:flex-initial {
    flex: 0 auto;
  }

  .sm\:flex-none {
    flex: none;
  }

  .sm\:flex-shrink {
    flex-shrink: 1;
  }

  .sm\:flex-shrink-0 {
    flex-shrink: 0;
  }

  .sm\:flex-grow {
    flex-grow: 1;
  }

  .sm\:flex-grow-0 {
    flex-grow: 0;
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .sm\:flex-col {
    flex-direction: column;
  }

  .sm\:flex-col-reverse {
    flex-direction: column-reverse;
  }

  .sm\:flex-wrap {
    flex-wrap: wrap;
  }

  .sm\:flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .sm\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .sm\:overflow-auto {
    overflow: auto;
  }

  .sm\:overflow-hidden {
    overflow: hidden;
  }

  .sm\:overflow-x-hidden {
    overflow-x: hidden;
  }

  .sm\:overflow-y-hidden {
    overflow-y: hidden;
  }

  .sm\:border-hidden {
    border-style: hidden;
  }
}

@media (width >= 992px) {
  .md\:row-auto {
    grid-row: auto;
  }

  .md\:-ml-1\/12 {
    margin-left: -8.33%;
  }

  .md\:-ml-10\/12 {
    margin-left: -83.33%;
  }

  .md\:-ml-11\/12 {
    margin-left: -91.66%;
  }

  .md\:-ml-2\/12 {
    margin-left: -16.66%;
  }

  .md\:-ml-3\/12 {
    margin-left: -25%;
  }

  .md\:-ml-4\/12 {
    margin-left: -33.33%;
  }

  .md\:-ml-5\/12 {
    margin-left: -41.66%;
  }

  .md\:-ml-6\/12 {
    margin-left: -50%;
  }

  .md\:-ml-7\/12 {
    margin-left: -58.333%;
  }

  .md\:-ml-8\/12 {
    margin-left: -66.66%;
  }

  .md\:-ml-9\/12 {
    margin-left: -75%;
  }

  .md\:-ml-full {
    margin-left: -100%;
  }

  .md\:ml-1 {
    margin-left: .25rem;
  }

  .md\:ml-1\/12 {
    margin-left: 8.33%;
  }

  .md\:ml-10\/12 {
    margin-left: 83.33%;
  }

  .md\:ml-11\/12 {
    margin-left: 91.66%;
  }

  .md\:ml-2\/12 {
    margin-left: 16.66%;
  }

  .md\:ml-3\/12 {
    margin-left: 25%;
  }

  .md\:ml-4\/12 {
    margin-left: 33.33%;
  }

  .md\:ml-5\/12 {
    margin-left: 41.66%;
  }

  .md\:ml-6\/12 {
    margin-left: 50%;
  }

  .md\:ml-7\/12 {
    margin-left: 58.333%;
  }

  .md\:ml-8\/12 {
    margin-left: 66.66%;
  }

  .md\:ml-9\/12 {
    margin-left: 75%;
  }

  .md\:ml-auto {
    margin-left: auto;
  }

  .md\:ml-full {
    margin-left: 100%;
  }

  .md\:block {
    display: block;
  }

  .md\:inline-block {
    display: inline-block;
  }

  .md\:flex {
    display: flex;
  }

  .md\:inline-flex {
    display: inline-flex;
  }

  .md\:table-cell {
    display: table-cell;
  }

  .md\:hidden {
    display: none;
  }

  .md\:w-1\/12 {
    width: 8.33333%;
  }

  .md\:w-10\/12 {
    width: 83.3333%;
  }

  .md\:w-11\/12 {
    width: 91.6667%;
  }

  .md\:w-2\/12 {
    width: 16.6667%;
  }

  .md\:w-3\/12 {
    width: 25%;
  }

  .md\:w-4\/12 {
    width: 33.3333%;
  }

  .md\:w-5\/12 {
    width: 41.6667%;
  }

  .md\:w-6\/12 {
    width: 50%;
  }

  .md\:w-7\/12 {
    width: 58.3333%;
  }

  .md\:w-8\/12 {
    width: 66.6667%;
  }

  .md\:w-9\/12 {
    width: 75%;
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:w-full {
    width: 100%;
  }

  .md\:min-w-full {
    min-width: 100%;
  }

  .md\:max-w-full {
    max-width: 100%;
  }

  .md\:flex-1 {
    flex: 1;
  }

  .md\:flex-auto {
    flex: auto;
  }

  .md\:flex-initial {
    flex: 0 auto;
  }

  .md\:flex-none {
    flex: none;
  }

  .md\:flex-shrink {
    flex-shrink: 1;
  }

  .md\:flex-shrink-0 {
    flex-shrink: 0;
  }

  .md\:flex-grow {
    flex-grow: 1;
  }

  .md\:flex-grow-0 {
    flex-grow: 0;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .md\:flex-col {
    flex-direction: column;
  }

  .md\:flex-col-reverse {
    flex-direction: column-reverse;
  }

  .md\:flex-wrap {
    flex-wrap: wrap;
  }

  .md\:flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .md\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .md\:overflow-auto {
    overflow: auto;
  }

  .md\:overflow-hidden {
    overflow: hidden;
  }

  .md\:overflow-x-hidden {
    overflow-x: hidden;
  }

  .md\:overflow-y-hidden {
    overflow-y: hidden;
  }

  .md\:border-hidden {
    border-style: hidden;
  }

  [components-theme="wip"] .wip\:md\:ml-0 {
    margin-left: 0;
  }

  [components-theme="wip"] .wip\:md\:ml-64 {
    margin-left: 16rem;
  }

  [components-theme="wip"] .wip\:md\:ml-8 {
    margin-left: 2rem;
  }

  [components-theme="wip"] .wip\:md\:w-full {
    width: 100%;
  }
}

@media (width >= 1200px) {
  .lg\:row-auto {
    grid-row: auto;
  }

  .lg\:-ml-1\/12 {
    margin-left: -8.33%;
  }

  .lg\:-ml-10\/12 {
    margin-left: -83.33%;
  }

  .lg\:-ml-11\/12 {
    margin-left: -91.66%;
  }

  .lg\:-ml-2\/12 {
    margin-left: -16.66%;
  }

  .lg\:-ml-3\/12 {
    margin-left: -25%;
  }

  .lg\:-ml-4\/12 {
    margin-left: -33.33%;
  }

  .lg\:-ml-5\/12 {
    margin-left: -41.66%;
  }

  .lg\:-ml-6\/12 {
    margin-left: -50%;
  }

  .lg\:-ml-7\/12 {
    margin-left: -58.333%;
  }

  .lg\:-ml-8\/12 {
    margin-left: -66.66%;
  }

  .lg\:-ml-9\/12 {
    margin-left: -75%;
  }

  .lg\:-ml-full {
    margin-left: -100%;
  }

  .lg\:ml-1\/12 {
    margin-left: 8.33%;
  }

  .lg\:ml-10\/12 {
    margin-left: 83.33%;
  }

  .lg\:ml-11\/12 {
    margin-left: 91.66%;
  }

  .lg\:ml-2\/12 {
    margin-left: 16.66%;
  }

  .lg\:ml-3\/12 {
    margin-left: 25%;
  }

  .lg\:ml-4\/12 {
    margin-left: 33.33%;
  }

  .lg\:ml-5\/12 {
    margin-left: 41.66%;
  }

  .lg\:ml-6\/12 {
    margin-left: 50%;
  }

  .lg\:ml-7\/12 {
    margin-left: 58.333%;
  }

  .lg\:ml-8\/12 {
    margin-left: 66.66%;
  }

  .lg\:ml-9\/12 {
    margin-left: 75%;
  }

  .lg\:ml-auto {
    margin-left: auto;
  }

  .lg\:ml-full {
    margin-left: 100%;
  }

  .lg\:block {
    display: block;
  }

  .lg\:inline-block {
    display: inline-block;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:inline-flex {
    display: inline-flex;
  }

  .lg\:table-cell {
    display: table-cell;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:w-1\/12 {
    width: 8.33333%;
  }

  .lg\:w-10\/12 {
    width: 83.3333%;
  }

  .lg\:w-11\/12 {
    width: 91.6667%;
  }

  .lg\:w-2\/12 {
    width: 16.6667%;
  }

  .lg\:w-3\/12 {
    width: 25%;
  }

  .lg\:w-4\/12 {
    width: 33.3333%;
  }

  .lg\:w-5\/12 {
    width: 41.6667%;
  }

  .lg\:w-6\/12 {
    width: 50%;
  }

  .lg\:w-7\/12 {
    width: 58.3333%;
  }

  .lg\:w-8\/12 {
    width: 66.6667%;
  }

  .lg\:w-9\/12 {
    width: 75%;
  }

  .lg\:w-auto {
    width: auto;
  }

  .lg\:w-full {
    width: 100%;
  }

  .lg\:min-w-full {
    min-width: 100%;
  }

  .lg\:max-w-full {
    max-width: 100%;
  }

  .lg\:flex-1 {
    flex: 1;
  }

  .lg\:flex-auto {
    flex: auto;
  }

  .lg\:flex-initial {
    flex: 0 auto;
  }

  .lg\:flex-none {
    flex: none;
  }

  .lg\:flex-shrink {
    flex-shrink: 1;
  }

  .lg\:flex-shrink-0 {
    flex-shrink: 0;
  }

  .lg\:flex-grow {
    flex-grow: 1;
  }

  .lg\:flex-grow-0 {
    flex-grow: 0;
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .lg\:flex-col {
    flex-direction: column;
  }

  .lg\:flex-col-reverse {
    flex-direction: column-reverse;
  }

  .lg\:flex-wrap {
    flex-wrap: wrap;
  }

  .lg\:flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .lg\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .lg\:overflow-auto {
    overflow: auto;
  }

  .lg\:overflow-hidden {
    overflow: hidden;
  }

  .lg\:overflow-x-hidden {
    overflow-x: hidden;
  }

  .lg\:overflow-y-hidden {
    overflow-y: hidden;
  }

  .lg\:border-hidden {
    border-style: hidden;
  }
}
/*# sourceMappingURL=provider-portal.c211e55c.css.map */
